import { Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IdleDialogComponent } from 'app/main/Idle/idle-dialog.component';
import { ALNIdentityAuthService } from '../../core/auth/aln-identity-auth.service';
import { environment } from 'environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  readonly MAX_IDLE_TIME = parseInt(environment.idleTimeOut);
  readonly Message_TimeOut = parseInt(environment.messageTimeOut);
  private isRunning: boolean = false;
  private lastActiveTime: number;
  private isConfirmationDialogOpen: boolean = false;
  private internalTimerID;
  confirmDialogRef: MatDialogRef<IdleDialogComponent>;
  constructor(private dialog: MatDialog, private _alnIdentityAuthService: ALNIdentityAuthService,public oidcSecurityService: OidcSecurityService, private ngZone: NgZone, 
    private injector: Injector) {
    this.resetTimer();
    this.watchForActivity();
  }

  private watchForActivity(): void {
    window.addEventListener('mousemove', this.resetTimer.bind(this));
    window.addEventListener('keypress', this.resetTimer.bind(this));
  }

  resetTimer(): void {
    if (!this.isConfirmationDialogOpen) {
      this.lastActiveTime = Date.now();
    }
  }

  private showTimeoutConfirmation(): void {
    this.isConfirmationDialogOpen = true;
    this.confirmDialogRef = this.dialog.open(IdleDialogComponent, { disableClose: false, data: { isAlert: true } });
    this.confirmDialogRef.componentInstance.title = 'Session Timeout';
    this.confirmDialogRef.componentInstance.timeoutWarning = this.Message_TimeOut;
    this.confirmDialogRef.afterClosed().subscribe(result => {
      this.isConfirmationDialogOpen = false;
      if (result === 'stay') {
        this.resetTimer();
      } else {
        console.log('Idle timer session logout called');
        this.logout();
      }
    });
  }


  isTimedout(): void {
    
    if (localStorage.getItem("userLoggedIn") && localStorage.getItem("userLoggedIn") === "false")
    {
      this.logout();
      return;
    }

    const timeSinceLastActive = (Date.now() - this.lastActiveTime) / 1000;
    if ((timeSinceLastActive + this.Message_TimeOut) >= this.MAX_IDLE_TIME && !this.isConfirmationDialogOpen) {
        this.showTimeoutConfirmation();
    }
    if (timeSinceLastActive > this.MAX_IDLE_TIME) {
      if(this.isConfirmationDialogOpen) this.isConfirmationDialogOpen = false;
      this.resetTimer();
      console.log('Idle timer session logout called');
      this.confirmDialogRef.close();
      this.logout();
    }
  }

  start(): void {
    if(!this.isRunning){
      this.isRunning = true;
      this.ngZone.runOutsideAngular(() => {
        this.internalTimerID = setInterval(() => {
          this.isTimedout();
        }, 1000); // check every second
      });
    }
  }

  stop(): void
  {
    this.isRunning = false;
    clearInterval(this.internalTimerID);
  }

  logout(): void
  {
    if (this._alnIdentityAuthService && typeof this._alnIdentityAuthService.logout === 'function') {
      this._alnIdentityAuthService.logout();
      return;
    }
    
    console.info(`Logging off via injector`);
    let alnIdentityAuthService  = this.injector.get(ALNIdentityAuthService);
    alnIdentityAuthService.logout();
  }
}
