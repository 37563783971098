<mat-toolbar class="p-0 mat-elevation-z1 header-wrapper" style="height: auto;">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" class="header-container">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" class="left">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div>
                    <img src="assets/images/logos/Logo_V4.png">
                </div>
            </div>


        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start start" class="right">
            <div class="px-8 px-md-16 left">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>
            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center" class="username">
                    <img class="avatar mr-0 mr-sm-16" src="{{userAvatar}}">
                    <span class="mr-12" fxHide fxShow.gt-sm>{{userFullName}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="goToMyProfile()" >
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <button mat-menu-item class="ChangePW-icon" (click)="goToChangePassword()">
                    <mat-icon>vpn_key</mat-icon>
                    <span>Change Password</span>
                </button>

                <button mat-menu-item class="" (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

<!--        FUSE_TEMP_HIDE     

            <fuse-search-bar (input)="search($event)"></fuse-search-bar>

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button> 
        -->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>
