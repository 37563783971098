import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule, OidcSecurityService, StsConfigLoader } from 'angular-auth-oidc-client';
import 'hammerjs';
import { MainModule } from './main/main.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { ALNIdentityAuthService } from './core/auth/aln-identity-auth.service';
import { CacheControlInterceptor } from './core/http-interceptors/cache-control-interceptor';
import { AuthInterceptor } from './core/http-interceptors/auth-Interceptor';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { SampleComponent } from './main/sample/sample.component';
import { AppRoutingModule } from './app-routing.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfigService, authFactory } from '@fuse/services/oidc-config.service';

const appRoutes: Routes = [
    {
        path      : '**', component: SampleComponent
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            anchorScrolling: 'enabled'
        }),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        MainModule,
        AppRoutingModule,
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: authFactory,
                deps: [ConfigService],
              },
          }),
        NgIdleKeepaliveModule.forRoot()
    ],

    providers: [
        ALNIdentityAuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheControlInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule {
    constructor(private oidcSecurityService: OidcSecurityService) {
        //this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
    }
}
