import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector   : 'idle-Confirmation-dialog',
    templateUrl: './idle-dialog.component.html',
    styleUrls  : ['./idle-dialog.component.scss']
})
export class IdleDialogComponent implements OnInit {
    public title: string;
    public timeoutWarning : number;
    idleState: string;
    constructor(
        public dialogRef: MatDialogRef<IdleDialogComponent>
    ) {

    }
    ngOnInit(): void {
        this.idleState = this.timeoutWarning.toString();
        let seconds: number = this.timeoutWarning;
        setInterval(() => {
            seconds--;
            this.idleState = `${seconds}`;
            if (seconds == 0) {
                this.dialogRef.close('logout');
            }
        }, 1000);
    }

}
