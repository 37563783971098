import { Injectable, Injector, EventEmitter } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { User } from 'app/core/models/user';
import { ALNIdentityAuthUser, OidcIdToken } from './aln-identity-user';
import { environment as env } from '../../../environments/environment';
import { Subscription, of } from 'rxjs';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Router } from '@angular/router';
import { SharedService } from '@fuse/services/shared.service';
import { RoleInfo } from '../models/role-info';
import { CookieService } from 'ngx-cookie-service';
import { IdleTimeoutService } from 'app/main/Idle/idleTimeout.service';
import jwt_decode from 'jwt-decode';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ALNIdentityAuthService {
    tokenchange: EventEmitter<string> = new EventEmitter();
    private _user: User = new User();
    hidden: boolean = true;
    listofProducts: Array<any> = [];
    rolePermission: RoleInfo;
    hasBeenInitialized: boolean = false; //this should be removed later, we need to move the auth check from contructor here so that we do not need to do this
    private _isAuthorized: boolean = false;

    public isAuthorized(delayInSeconds: number): boolean {
        if(!sessionStorage.getItem('exp')) return false;
        var currentTimeInSeconds = Math.floor((new Date).getTime() / 1000);
        let condition = Number(sessionStorage.getItem('exp')) > currentTimeInSeconds + delayInSeconds;
        return condition;
    }

    get user(): User {
        if (!this.isAuthorized(-30) && !this._isAuthorized) {
            this._user = new User();
        }
        return this._user;
    }
    private _routeSub1 = new Subscription();
    private _routeSub2 = new Subscription();
    constructor(
        public oidcSecurityService: OidcSecurityService,
        private _fuseNavigationService: FuseNavigationService,
        private injector: Injector,
        private router: Router, public _data: SharedService,private cookieService: CookieService) {
        
        // Added below lines to avoid OIDC CheckAuth method for NetDocs iframe issue(Ticket: 27471), as CheckAuth fails on NetDocs Iframe call.
        let currentNetDocIndex = window?.location?.pathname?.indexOf(env.netDocsRedirectUrlPath);
        if(currentNetDocIndex && currentNetDocIndex > -1){
            return;
        }

        this.performAuth();
    }

    private performAuth() {


        this._routeSub2= this.oidcSecurityService
        .checkAuth()
        .subscribe((loginResponse: LoginResponse) => {
            this._isAuthorized = loginResponse?.isAuthenticated;
            if (loginResponse?.isAuthenticated) {

                if(loginResponse?.idToken){
                    let oidcIdToken : OidcIdToken = jwt_decode(loginResponse.idToken);
                    if(oidcIdToken?.exp){
                        sessionStorage.setItem('exp', oidcIdToken?.exp.toString()); 
                    }
                }

                let token = this.oidcSecurityService
                    .getAccessToken()
                    .pipe(
                        catchError(err => {
                            console.error('Error getting Access Token: ', err);
                            return of(null);
                        })
                    )
                    .subscribe((accessToken: string) => {

                        if (accessToken) {
                            this.mapUser(accessToken);
                            const userData: ALNIdentityAuthUser = jwt_decode(accessToken);
                            sessionStorage.setItem('token', accessToken);
                            this.emitTokenChangeEvent(accessToken);
                            sessionStorage.setItem('userData', JSON.stringify(userData));
                            sessionStorage.setItem('userId', JSON.stringify(userData.userId)); 
                            localStorage.setItem("userLoggedIn", "true");
                            localStorage.setItem("lastActiveDT", Date.now().toString());
    
                            let idleTimeoutService  = this.injector.get(IdleTimeoutService);
                            idleTimeoutService.start();
                        } else {
                            console.warn('No Access token received');
                        }
                    });                 
                
            } else {

                //We can ignore this, since this has not been set yet
                if(!this.hasBeenInitialized)
                {
                    return;
                }
                let val = localStorage.getItem("userLoggedIn");
                if(val && val === "true")
                {
                    var appURL=`${env.clientUrl}`+"/";
                    if (window.location.toString() === appURL) {
                        this.logout();
                    }
                }
            }
        });

    }

    emitTokenChangeEvent(token: string) {
        this.tokenchange.emit(token);
    }
    
    getTokenChangeEvent() {
        return this.tokenchange;
    }

    login() {
        this.oidcSecurityService.authorize();
    }
    ngOnDestroy() {
        this._routeSub1.unsubscribe();
        this._routeSub2.unsubscribe();
    }
    logout() {
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("userLoggedIn", "false");
        this.cookieService.deleteAll();
        
        let idleTimeoutService  = this.injector.get(IdleTimeoutService);
        idleTimeoutService.stop();

        let urlForExternalLogout = `${env.stsUrl}/account/ExternalLogout?client=${env.clientId}&returnUrl=${env.clientUrl}`;
        this.oidcSecurityService
            .logoff()
            .subscribe((result) => window.location.href = urlForExternalLogout );
    }

    profile() {
        window.location.href = `${env.myProfileUri}`;
    }
    changePassword() {
        window.location.href = `${env.changePassword}`;
    }

    private mapUser(bearerToken: string) {
        
        const userData: ALNIdentityAuthUser = jwt_decode(bearerToken);

        const parseObj = (values) => Array.isArray(values)
            ? values.map(v => JSON.parse(v))
            : [JSON.parse(values)];

        const userInfo = new ALNIdentityAuthUser(userData);
        this._user.userId = userInfo.userId;
        this._user.userName = userInfo.preferred_username;
        this._user.fullName = userInfo.name;
        this._user.profileImageURL = userInfo.profilePictureUrl.startsWith("https://")
            ? userInfo.profilePictureUrl
            : `${env.stsUrl}${userInfo.profilePictureUrl}`;
        this._user.bearerToken = bearerToken;
        this._user.organisation = userInfo.organizationName;
        this._user.applicationRoles = Array.isArray(userInfo.userRoles)
            ? userInfo.userRoles
            : [userInfo.userRoles];
        sessionStorage.setItem('OrganizationId', JSON.stringify(userInfo.organizationGUID));

        this._user.clients = userInfo.products ? parseObj(userInfo.products) : [];
        this._user.allClients = userInfo.allProducts ? parseObj(userInfo.allProducts) : [];

        if (this._user.clients.length > 0) {
            sessionStorage.setItem('userProducts', JSON.stringify(this._user.clients));
            sessionStorage.setItem('allProducts', JSON.stringify(this._user.allClients));
        }

        let productId = 'eDockets'
        var products = this._user.clients ? this._user.clients.filter(x => x.clientCode == productId) : [];
        this.rolePermission = this.prepareRolePermissionsInfo(products);
        sessionStorage.setItem("roleAccessPermissions", JSON.stringify(this.rolePermission));

        this.listofProducts = [];
        var _user = sessionStorage.getItem('userProducts');
        if (_user) {
            this.listofProducts = JSON.parse(_user);
            if (this.listofProducts.filter(x => x.clientCode == productId).length == 0) {
                this._data.sendMessage(true);
            }
            else {
                this._data.sendMessage(false);
            }
        }
        else {
            this._data.sendMessage(false);
        }
        if (this._user.allClients.length > 0) {
            for (let i = 0; i < this._user.allClients.length; i++) {
                if (this._user.allClients[i]["isSubscribed"]) {
                    this.hidden = false;
                }
                else{
                    this.hidden = true;
                }
                
                this._fuseNavigationService.updateNavigationItem(this._user.allClients[i]["clientId"].toLowerCase(), {
                    url: this._user.allClients[i]["clientUri"],
                    hidden: this.hidden
                });

                if (this._user.allClients[i].clientName == 'Milana') {
                    this._fuseNavigationService.updateNavigationItem("dashboard", {
                        hidden: !this.rolePermission.DashBoardAccess
                    });
                    this._fuseNavigationService.updateNavigationItem("case-management", {
                        hidden: !this.rolePermission.CaseManagementAccess
                    });
                    this._fuseNavigationService.updateNavigationItem("eDocketsCalendar", {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem("Administrators", {
                        hidden: !this.rolePermission.SystemAdministrator
                    });
                    this._fuseNavigationService.updateNavigationItem("autodocket", {
                        hidden: !this.rolePermission.UseAutoDocket
                    });
                    this._fuseNavigationService.updateNavigationItem("MilanaQuickDates", {
                        hidden: !this.rolePermission.UseQuickDates
                    });
                    this._fuseNavigationService.updateNavigationItem("cr-management", {
                        hidden: !this.rolePermission.UseCourtRulesReport
                    });
                    this._fuseNavigationService.updateNavigationItem("docketalerts", {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem("applications", {
                        hidden: false
                    });
                }
            }
        }
        this.redirectUrlBasedonRole();

    }
    redirectUrlBasedonRole() {
        const storageKey: string = "currentPostLoginUrl";
        const landingKey: string = "adLandingPage";
        let urlFromStorage = sessionStorage.getItem(storageKey);
        let url: string = urlFromStorage;
        if (urlFromStorage) {
            sessionStorage.setItem(storageKey, '/');
        }
        
        const landingPage = JSON.parse(sessionStorage.getItem(landingKey));
        if (landingPage) {
            url = landingPage;
            sessionStorage.removeItem(landingKey);
        }
    
        if (!url || url === "/" || url === "null" || url === "undefined") {
            let originUrl = new URL(window.location.href);
            url = originUrl.pathname + originUrl.search + originUrl.hash;
        }
        if (!url || url === "/" || url === "null" || url === "undefined" || url.includes("appgateway")) {
            this.redirectRoute();
        } else {
            if (url.includes("/autodocket")) {
                this.navigateBasedOnRole(url, this.rolePermission.UseAutoDocket);
            } else if (url.includes("/case-management")) {
                this.navigateBasedOnRole(url, this.rolePermission.CaseManagementAccess);
            } else if (url.includes("/dashboard")) {
                this.navigateBasedOnRole(url, this.rolePermission.DashBoardAccess);
            } else {
                this.router.navigateByUrl(url);
            }
        }
    }
    
    navigateBasedOnRole(url: string, rolePermission: boolean) {
        if (rolePermission) {
            this.router.navigateByUrl(url);
        } else {
            this.redirectRoute();
        }
    }
    
    redirectRoute() {
        if (this.rolePermission.CaseManagementAccess) {
            this.router.navigate(['/case-management']);
        } else if (this.rolePermission.DashBoardAccess) {
            this.router.navigate(['/dashboard']);
        } else if (this.rolePermission.UseAutoDocket) {
            this.router.navigate(['/autodocket']);
        } else {
            this.router.navigate(['/eDockets/calendar']);
        }
    }
    
    prepareRolePermissionsInfo(userProducts: any) {
        this.rolePermission = new RoleInfo();
        if (userProducts.length > 0) {
            this.rolePermission.isAdmin = (userProducts[0].clientRole == "Administrator" || userProducts[0].clientRole == "Admin") ? true : false;
            if (userProducts[0].roleParametersList.length > 0) {
                userProducts[0].roleParametersList.forEach(element => {
                    var roleParamValue = element.roleParametersValue == "true" ? true : false;
                    element.roleParametersName=element.roleParametersName.trim();
                    if (element.roleParametersName == "Edit")
                        this.rolePermission.EditAccess = roleParamValue;

                    if (element.roleParametersName == "Delete")
                        this.rolePermission.DeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Client View")
                        this.rolePermission.ClientViewAccess = roleParamValue;

                    if (element.roleParametersName == "Client Add")
                        this.rolePermission.ClientAddAccess = roleParamValue;

                    if (element.roleParametersName == "Client Edit")
                        this.rolePermission.ClientEditAccess = roleParamValue;

                    if (element.roleParametersName == "Client Delete")
                        this.rolePermission.ClientDeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Matter View")
                        this.rolePermission.MatterViewAccess = roleParamValue;

                    if (element.roleParametersName == "Matter Add")
                        this.rolePermission.MatterAddAccess = roleParamValue;

                    if (element.roleParametersName == "Matter Edit")
                        this.rolePermission.MatterEditAccess = roleParamValue;

                    if (element.roleParametersName == "Matter Delete")
                        this.rolePermission.MatterDeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Case View")
                        this.rolePermission.CaseViewAccess = roleParamValue;

                    if (element.roleParametersName == "Case Add")
                        this.rolePermission.CaseAddAccess = roleParamValue;

                    if (element.roleParametersName == "Case Edit")
                        this.rolePermission.CaseEditAccess = roleParamValue;

                    if (element.roleParametersName == "Case Delete")
                        this.rolePermission.CaseDeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Docket View")
                        this.rolePermission.DocketViewAccess = roleParamValue;

                    if (element.roleParametersName == "Docket Add")
                        this.rolePermission.DocketAddAccess = roleParamValue;

                    if (element.roleParametersName == "Docket Edit")
                        this.rolePermission.DocketEditAccess = roleParamValue;

                    if (element.roleParametersName == "Docket Delete")
                        this.rolePermission.DocketDeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Event View")
                        this.rolePermission.EventViewAccess = roleParamValue;

                    if (element.roleParametersName == "Event Add")
                        this.rolePermission.EventAddAccess = roleParamValue;

                    if (element.roleParametersName == "Event Edit")
                        this.rolePermission.EventEditAccess = roleParamValue;

                    if (element.roleParametersName == "Event Delete")
                        this.rolePermission.EventDeleteAccess = roleParamValue;

                    if (element.roleParametersName == "System Admin View")
                        this.rolePermission.SysAdminViewAccess = roleParamValue;

                    if (element.roleParametersName == "System Admin Add")
                        this.rolePermission.SysAdminAddAccess = roleParamValue;

                    if (element.roleParametersName == "System Admin Edit")
                        this.rolePermission.SysAdminEditAccess = roleParamValue;

                    if (element.roleParametersName == "System Admin Delete")
                        this.rolePermission.SysAdminDeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Judge/Party View")
                        this.rolePermission.Judge_Party_ViewAccess = roleParamValue;

                    if (element.roleParametersName == "Judge/Party Add")
                        this.rolePermission.Judge_Party_AddAccess = roleParamValue;

                    if (element.roleParametersName == "Judge/Party Edit")
                        this.rolePermission.Judge_Party_EditAccess = roleParamValue;

                    if (element.roleParametersName == "Judge/Party Delete")
                        this.rolePermission.Judge_Party_DeleteAccess = roleParamValue;

                    if (element.roleParametersName == "Cascading Text Tool")
                        this.rolePermission.CascadingTextTool = roleParamValue;

                    if (element.roleParametersName == "Use Reports")
                        this.rolePermission.UseReports = roleParamValue;

                    if (element.roleParametersName == "AutoReport Scheduling")
                        this.rolePermission.AccessAutoReportScheduling = roleParamValue;

                    if (element.roleParametersName == "Use AutoDocket")
                        this.rolePermission.UseAutoDocket = roleParamValue;

                    if (element.roleParametersName == "Email Case Team")
                        this.rolePermission.EmailCaseTeam = roleParamValue;

                    if (element.roleParametersName == "System Administrator")
                        this.rolePermission.SystemAdministrator = roleParamValue;

                    if (element.roleParametersName == "Use PowerBI Reports")
                        this.rolePermission.UsePowerBIReports = roleParamValue;

                    if (element.roleParametersName == "Quick Dates")
                        this.rolePermission.UseQuickDates = roleParamValue;

                    if (element.roleParametersName == "Court Rules Report")
                        this.rolePermission.UseCourtRulesReport = roleParamValue;

                    if (element.roleParametersName == "Attorney View")
                        this.rolePermission.Attorney_ViewAccess = roleParamValue;

                    if (element.roleParametersName == "Attorney Add")
                        this.rolePermission.Attorney_AddAccess = roleParamValue;

                    if (element.roleParametersName == "Attorney Edit")
                        this.rolePermission.Attorney_EditAccess = roleParamValue;

                    if (element.roleParametersName == "Attorney Delete")
                        this.rolePermission.Attorney_DeleteAccess = roleParamValue;
                    if (element.roleParametersName == "Milana ®")
                        this.rolePermission.CaseManagementAccess = roleParamValue;
                    if (element.roleParametersName == "Dashboard")
                        this.rolePermission.DashBoardAccess = roleParamValue;
                    if (element.roleParametersName == "Calendar SelfAccess Only")
                        this.rolePermission.CalenderSelfAccess = roleParamValue;
                });
            }
            if (!!userProducts[0].clientFeatures) {
                userProducts[0].clientFeatures.forEach(clientFeature => {
                    if (clientFeature.featureName == "DocketDirect") {
                        this.rolePermission.DocketDirectEnabled = true;
                    }
                });
            }
            return this.rolePermission;
        }
        return this.rolePermission;
    }
}